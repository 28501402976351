import { Environments } from './environments.interface';

const isProd: any = 'false';

export const environment: Environments = {
  production: Boolean(isProd === 'true'),
  API_URL: {
    RECORDS: 'https://api-dev.sidedrawerdev.com/api/v1/records',
    SUBSCRIPTIONS: 'https://api-dev.sidedrawerdev.com/api/v1/subscriptions',
    CONFIGS: 'https://api-dev.sidedrawerdev.com/api/v1/configs',
    USERS: 'https://api-dev.sidedrawerdev.com/api/v1/users',
    DEVELOPER: 'https://api-dev.sidedrawerdev.com/api/v1/developers',
    INTEGRATION: 'https://api-dev.sidedrawerdev.com/api/v1/integrations',
    PLANS: 'https://api-dev.sidedrawerdev.com/api/v1/planrequests',
    TENANTS: 'https://api-dev.sidedrawerdev.com/api/v1/tenants',
  },
  AUTH0: {
    DOMAIN: 'acc-stg.sidedrawer.com',
    CLIENT_ID: 'AMQHxWGPqPTmNK4hpVEfT2LTf6ZU1q5b',
    AUDIENCE: 'https://user-api-stg.sidedrawer.com',
  },
  SD_TENANT_ID: '5f523f29e4a872fe42d9f7fb', // Get this value from sdrw-developer-api MongoDB collection.
};
